import React, { useState } from "react";
import { Row, Col, Container, Nav } from "react-bootstrap";
import "react-slideshow-image/dist/styles.css";
import { isMobile } from "react-device-detect";
import { Slide } from "react-slideshow-image";
import ai1 from "../assets/images/ai1.svg";
import ai2 from "../assets/images/ai2.svg";
import ai3 from "../assets/images/ai3.svg";
import protocolaiLogo from "../assets/images/news/protocolai-logo.jpg";

import introEye from "../assets/images/intro-eye.svg";
import service1 from "../assets/images/service-1.jpg";
import service2 from "../assets/images/service-2.jpg";
import service3 from "../assets/images/service-3.jpg";
import close from "../assets/images/x.svg";
import chevron from "../assets/images/chevron.svg";
import Layout from "../components/layout";
import SEO from "../components/seo";
import NewNavbar from "../components/navbar";
import axios from "axios";
import { Link } from "gatsby";
import { API_ROOT } from "gatsby-env-variables";
import runCrisp from "../../scripts/crisp";
import beAdvnaced from "../assets/images/logo-be-advanced.svg";
import sitemsinsel from "../assets/images/sitemsinsel.png";
import unversiteDeGeneve from "../assets/images/universite.png";
import unversitatBern from "../assets/images/bern.png";
import Florian from "../assets/images/florian.png";
import Quentin from "../assets/images/quantin.png";
import Philip from "../assets/images/philip.png";
import Joel from "../assets/images/joel.png";
import Blaga from "../assets/images/blaga.png";
import Zillah from "../assets/images/Zillah@4x.png";
import Stefan from "../assets/images/stefan.png";
import Poorya from "../assets/images/poorya.png";
import Hossein from "../assets/images/hossein.png";
import Mastane from "../assets/images/mastane.png";
import DefaultUser from "../assets/images/default-user.png";
import BeatWilder from "../assets/images/Dr. Beat Widler.png";
import JenniferKealy from "../assets/images/JenniferKealy.png";
import IngridKlingmann from "../assets/images/Dr Ingrid Klingmann.png";
import SvenTrelle from "../assets/images/PD._Dr._Sven_Trelle.png";
import { isAfter } from "date-fns";

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventBanner: true,
      displaySecondBanner: false,
      newList: [],
      ourMission: "",
      team: [],
      researchTeam: [],
      advisory: [],
      partners: [],
      homeHeadersTitle: "",
      homeHeadersDescription: "",
      homeFeatures: [],
      // homeServices: [],
      aboutUs: "",
      showModal: true,
      pageTitle: "index",
      metaArr: [],
      minHeight: null,
      slideImages: [
        "images/slide_2.jpg",
        "images/slide_3.jpg",
        "images/slide_4.jpg",
      ],
    };
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleEventBanner = this.handleEventBanner.bind(this);
  }
  componentDidMount() {
    runCrisp();
    let showModal = localStorage.getItem("showModal") ?? true;
    this.setState({
      showModal: showModal === "false" ? false : true,
    });

    // this.getSetting();
    this.getTeam();
    this.getAdvisory();
    this.getPartner();
    // this.getSeoData();
    this.getNewsList(1);

    this.setState({
      minHeight: window.innerHeight,
    });
    window.addEventListener("resize", () => {
      this.setState({
        minHeight: window.innerHeight,
      });
    });
    this.checkDate();
  }
  checkDate() {
    const currentDate = new Date();
    const eventDate = new Date("2024-04-23T17:00:00");

    if (isAfter(currentDate, eventDate)) {
      this.setState({ displaySecondBanner: true });
      console.log("is After?", isAfter(currentDate, eventDate));
    }
  }
  // getSeoData() {
  //   axios.get(API_ROOT + `/api/v2/setting/home_page`).then((response) => {
  //     var data = response?.data?.data || [];
  //     var metaArr = [];
  //     data.map((e) => {
  //       if (e.type === "title") {
  //         this.setState({
  //           pageTitle: e.value,
  //         });
  //       } else {
  //         metaArr.push({
  //           name: e.type,
  //           content: e.value,
  //         });
  //       }
  //     });
  //
  //     this.setState({
  //       metaArr: metaArr,
  //     });
  //   });
  // }
  // getSetting() {
  //   axios.get(API_ROOT + `/api/v1/settings`).then((response) => {
  //     if (response && response.data && response.data.data) {
  //       this.setState({
  //         aboutUs: response.data.data.about_us,
  //         homeHeadersTitle: response.data.data.home_headers.title,
  //         homeHeadersDescription: response.data.data.home_headers.description,
  //         homeFeatures: response.data.data.home_features,
  //         // homeServices: response.data.data.home_services
  //       });
  //     }
  //   });
  // }
  getTeam() {
    axios.get(API_ROOT + `/api/v2/teams/?team=staff`).then((response) => {
      var team = [];
      var researchTeam = [];
      if (response && response.data && response.data.data) {
        response.data.data.map((e) => {
          if (e.type === "research") {
            researchTeam.push(e);
          } else {
            team.push(e);
          }
        });

        team.sort((a, b) => a.last_name.localeCompare(b.last_name));

        this.setState({
          team: team.splice(0, 4),
          researchTeam: researchTeam,
        });
      }
    });
  }
  getAdvisory() {
    axios.get(API_ROOT + "/api/v2/teams/?team=advisory").then((response) => {
      if (response && response.data && response.data.data) {
        this.setState({
          advisory: response.data.data,
        });
      }
    });
  }
  getPartner() {
    axios.get(API_ROOT + `/api/v2/teams/?team=partner`).then((response) => {
      if (response && response.data && response.data.data) {
        this.setState({
          partners: response.data.data,
        });
      }
    });
  }
  handleShowModal() {
    localStorage.setItem("showModal", false);
    this.setState({
      showModal: false,
    });
  }
  getNewsList(page) {
    axios
      .get(API_ROOT + `/api/v2/posts`, {
        params: {
          page: page,
        },
      })
      .then((response) => {
        if (response && response.data && response.data.data) {
          this.setState({
            newList: response.data.data,
          });
        }
      });
  }

  handleEventBanner = () => {
    this.setState({ eventBanner: false });
  };

  render() {
    const advisoryBoard = [
      {
        id: 1,
        name: "Dr. Beat Widler",
        description:
          "Beat was the Initiator and former leader of the Roche Pharma Clinical Quality Risk Management (C-QRM) project for clinical development and pharmacovigilance for 25 years. Beat led more than 150 clinical trial center audits worldwide.",
        imgSrc: BeatWilder,
      },
      /* {
        id: 2,
        name: "Jennifer Kealy",
        description:
          "Jennifer is a consultant scientist at WHO, and a global health expert with 20+ years of experience in clinical research and development of medicines, vaccines and In-Vitro diagnostics. She was head of quality and risk management in Swiss TPH25.",
        imgSrc: JenniferKealy,
      }, */
      {
        id: 3,
        name: "Dr. Ingrid Klingmann",
        description:
          "Ingrid is President of PharmaTrain Federation asbl and founder and Managing Director at Pharmaplex. She is a distinguished expert in medicines development and site management.",
        imgSrc: IngridKlingmann,
      },
      {
        id: 4,
        name: "PD. Dr. Sven Trelle",
        description:
          "Sven is the Strategic lead and manager of the clinical trial unit (CTU) Bern. Sven is a recognized expert in the development of study design as well as the conduct and analysis of patient-oriented clinical studies.",
        imgSrc: SvenTrelle,
      },
    ];

    const advisoryBoardSets = [];
    for (let i = 0; i < advisoryBoard.length; i += 4) {
      const set = advisoryBoard.slice(i, i + 4);
      advisoryBoardSets.push(set);
    }

    const teamMembers = [
      { id: 1, name: "Dr. Poorya Amini", position: "CEO", profilePic: Poorya },
      { id: 2, name: "Dr. Quentin Haas", position: "CSO", profilePic: Quentin },
      {
        id: 3,
        name: "Philipp Khlebnikov",
        position: "Data Scientist",
        profilePic: Philip,
      },
      {
        id: 4,
        name: "Florian Meer",
        position: "Data Scientist",
        profilePic: Florian,
      },
      {
        id: 5,
        name: "Mastane Kachloo",
        position: "Project Manager",
        profilePic: Mastane,
      },
      {
        id: 6,
        name: "Hossein Maaleki",
        position: "UI/UX Designer",
        profilePic: Hossein,
      },
      {
        id: 7,
        name: "Joel Kuehl",
        position: "Full Stack Developer",
        profilePic: Joel,
      },
      {
        id: 8,
        name: "Blagica Bozhinovska",
        position: "Full Stack Developer",
        profilePic: Blaga,
      },
      {
        id: 9,
        name: "Stefan Cvetanovski",
        position: "Full Stack Developer",
        profilePic: Stefan,
      },
      {
        id: 10,
        name: "Zillah Clauss",
        position: "Business Developer",
        profilePic: Zillah,
      },
      {
        id: 11,
        name: "Ivan Jankuloski",
        position: "DevOps Engineer",
        profilePic: DefaultUser,
      },
      // Add more team members as needed
    ];

    const teamMemberSets = [];
    for (let i = 0; i < teamMembers.length; i += 4) {
      const set = teamMembers.slice(i, i + 4);
      teamMemberSets.push(set);
    }

    const partners = [
      { id: 1, srcImg: beAdvnaced },
      { id: 2, srcImg: sitemsinsel },
      { id: 3, srcImg: unversiteDeGeneve },
      { id: 4, srcImg: unversitatBern },
    ];
    const { displaySecondBanner } = this.state;
    return (
      <Layout pageInfo={{ pageName: "index" }}>
        <SEO title={this.state.pageTitle} meta={this.state.metaArr} />

        <NewNavbar />
        <div className="page-container">
          <Container className="">
            {/*first section*/}

            <Row>
              <div
                className="intro-section"
                style={
                  !isMobile ? { minHeight: this.state.minHeight } : undefined
                }
              >
                <div className="fullHeight">
                  <Row
                    className={
                      "first-section-card d-flex align-items-center sized-container"
                    }
                  >
                    <Col md={{ span: 6, offset: 0 }}>
                      <p
                        className={`clinical-trial-title ${isMobile && "mt4"}`}
                      >
                        Develop the Clinical Trial <br /> Protocols of the
                        Future Using AI
                      </p>
                      <p>
                        Discover the first-in-class AI software reinventing
                        clinical trial protocol development. Protocol AI
                        empowers experts to conduct faster, cheaper and safer
                        trials, paving the way to medical innovation​.
                      </p>
                      {/* <p>{this.state.homeHeadersTitle}</p>
                      <p>{this.state.homeHeadersDescription}</p> */}
                    </Col>
                    <Col
                      md={{ span: 6, offset: 0 }}
                      xl={{ span: 5, offset: 1 }}
                    >
                      <img src={introEye} />
                    </Col>
                  </Row>
                </div>
              </div>
            </Row>
          </Container>
        </div>
        {/*risklick AI*/}
        {this.state.eventBanner &&
          (!displaySecondBanner ? (
            <div className="page-container">
              <div className="event-container">
                <div className="event-info">
                  <div className="event-info-details">
                    <div className="event-text">
                      <p className="event-title">
                        Trial protocol of the future
                      </p>
                      <p className="event-sub-title">
                        Launch event of Protocol AI
                      </p>
                      <p className="event-date">on April 23rd, 2024</p>
                    </div>
                    <div className="event-btn">
                      <button className="event-more">
                        <a href="/Artboard1.pdf" download>
                          More...
                        </a>
                      </button>
                      <button className="event-register">
                        <a
                          href="https://forms.gle/FFTqDNqnb3i4wrGN7"
                          target="_blank"
                        >
                          Register Now
                        </a>
                      </button>
                    </div>
                  </div>

                  <div className="event-close">
                    <button
                      onClick={() => this.handleEventBanner()}
                      className="event-close"
                    >
                      <img src={close} alt="x" width={10} height={10} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="page-container">
              <div className="event-container">
                <div className="event-info">
                  <div className="event-info-details">
                    <div className="event-text">
                      <p className="event-title">
                        Trial Protocol of the Future
                      </p>
                      <p className="event-sub-title">
                        Protocol AI Launch Event was a success!
                      </p>
                    </div>
                    <div className="event-btn">
                      <button className="event-more">
                        <Link to="/news/protocol-ai-launch-event">More...</Link>
                      </button>
                      <button className="event-register">
                        <a href="https://protocolai.ch/home" target="_blank">
                          Request a demo
                        </a>
                      </button>
                    </div>
                  </div>
                  <div className="event-close">
                    <button
                      onClick={() => this.handleEventBanner()}
                      className="event-close"
                    >
                      <img src={close} alt="x" width={10} height={10} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        <div id="product">
          <div className="page-container">
            <div className={`fullHeight ${!isMobile ? "mt100" : ""}`}>
              {/* <Row className={"d-flex justify-content-center"}>
                <p className={"sections-up-title"}>What do we do?</p>
              </Row> */}
              <Row className={"d-flex justify-content-center"}>
                <p className={"sections-title "}>Risklick Technology</p>
              </Row>
              <div className=" slider-grid mt100">
                <Slide
                  indicators={true}
                  autoplay={false}
                  duration={2000}
                  style={{ width: "100%" }}
                  className="home_products_slider"
                >
                  <Row className="each-slide">
                    <div
                      className={
                        "align-middle d-flex align-items-center justify-content-center product-image"
                      }
                    >
                      <img
                        src={ai1}
                        alt="risklick AI"
                        className="d-flex align-items-center"
                      />
                    </div>
                    <div
                      className={
                        "align-middle d-flex align-items-center align-self-center justify-content-start product-content"
                      }
                    >
                      <div className="content-box">
                        <p className={"title"}>
                          Quality by Design
                          {/* {this.state.homeFeatures &&
                            this.state.homeFeatures[0] &&
                            this.state.homeFeatures[0].title} */}
                        </p>
                        <p className={`content ${isMobile && "mt100"}`}>
                          Risklick's transformative technology empowers experts
                          to harness the power of data for their trial protocol
                          development. Protocol AI eases protocol development
                          and ensures consistency, leading to significant time
                          and cost savings. Orchestrate your clinical trial with
                          an optimized protocol, accelerating innovation and the
                          delivery of new treatments to patients.
                          {/* {this.state.homeFeatures &&
                            this.state.homeFeatures[0] &&
                            this.state.homeFeatures[0].description} */}
                        </p>
                      </div>
                    </div>
                  </Row>
                  <Row className="each-slide">
                    <Col
                      md={4}
                      className={
                        "align-middle d-flex align-items-center justify-content-center"
                      }
                    >
                      <img
                        src={ai2}
                        alt="risklick AI"
                        className="d-flex align-items-center"
                      />
                    </Col>
                    <Col
                      md={8}
                      className={
                        "align-middle d-flex align-items-center justify-content-center"
                      }
                    >
                      <Col className={"content-box"} lg={{ span: 11 }}>
                        <p className={"title"}>
                          Critical Thinking and Historical Benchmarks
                          {/* {this.state.homeFeatures &&
                            this.state.homeFeatures[1] &&
                            this.state.homeFeatures[1].title} */}
                        </p>
                        <p className={"content"}>
                          Our AI models are trained on global historical trial
                          data, including protocols, associated publications,
                          and regulatory documents. Protocol AI empowers experts
                          to create precise benchmarks for protocol development,
                          seamlessly incorporating insights from benchmarks and
                          regulatory decisions to select the most pertinent
                          parameters."
                          {/* {this.state.homeFeatures &&
                            this.state.homeFeatures[1] &&
                            this.state.homeFeatures[1].description} */}
                        </p>
                      </Col>
                    </Col>
                  </Row>
                  <Row className="each-slide">
                    <Col
                      md={4}
                      className={
                        "align-middle d-flex align-items-center justify-content-center"
                      }
                    >
                      <img
                        src={ai3}
                        alt="risklick AI"
                        className="d-flex align-items-center"
                      />
                    </Col>
                    <Col
                      md={8}
                      className={
                        "align-middle d-flex align-items-center justify-content-center"
                      }
                    >
                      <Col className={"content-box"} lg={{ span: 11 }}>
                        <p className={"title"}>
                          Risklick Performance
                          {/* {this.state.homeFeatures &&
                            this.state.homeFeatures[2] &&
                            this.state.homeFeatures[2].title} */}
                        </p>
                        <p className={"content"}>
                          Risklick AI models are validated using 16,000 clinical
                          trials, demonstrating a precision rate exceeding 90%.
                          Developed and tested in collaboration with experts,
                          the performance of Risklick AI models has been
                          published in peer-reviewed scientific journals.
                          {/* {this.state.homeFeatures &&
                            this.state.homeFeatures[2] &&
                            this.state.homeFeatures[2].description} */}
                        </p>
                      </Col>
                    </Col>
                  </Row>
                </Slide>
              </div>
            </div>
          </div>
        </div>
        <div className="page-container">
          <Container className="fullWidth">
            {/* benefits */}
            <Row id="protocol-ai-benefits">
              <Col className={`fullHeight  ${!isMobile ? "mt100" : "mt40"}`}>
                {/* <Row className={"d-flex justify-content-center"}>
                  <p className={"sections-up-title"}>How can we assist you?</p>
                </Row> */}
                <div className="new-benefits">
                  <div className="new-benefits-left-spacer"></div>
                  <div className="new-benefits-title">
                    <p className={"sections-title benefits-title"}>Benefits</p>
                  </div>

                  <div className="new-benefits-content">
                    <div className="new-benefits-image">
                      {/* <Row className={`d-flex justify-content-center ${isMobile && 'self-center'}`}> */}

                      {/* </Row> */}
                      <img
                        src={require("../assets/images/protocol-ai.png")}
                        width={750}
                        height={500}
                      />
                    </div>

                    <div className="new-benefits-steps-container">
                      <div className="new-benefits-steps">
                        <p className="new-benefits-steps-icon">
                          <img
                            src={require("../assets/images/reduce-benefits.svg")}
                            width={40}
                            height={40}
                          />
                        </p>
                        <p className="new-benefits-steps-p">
                          {" "}
                          <span>Reduce</span> protocol development time
                        </p>
                      </div>
                      <div className="new-benefits-steps">
                        <p className="new-benefits-steps-icon">
                          <img
                            src={require(`../assets/images/evidence-benefits.svg`)}
                            width={40}
                            height={40}
                          />
                        </p>
                        <p className="new-benefits-steps-p">
                          {" "}
                          <span>Evidence-based</span> decision making
                        </p>
                      </div>
                      <div className="new-benefits-steps">
                        <p className="new-benefits-steps-icon">
                          <img
                            src={require(`../assets/images/minimize-benefits.svg`)}
                            width={40}
                            height={40}
                          />
                        </p>
                        <p className="new-benefits-steps-p">
                          {" "}
                          <span>Minimize</span> likelihood of amendments
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="new-benefits-right-spacer"></div>
                  {/* <img
                    src={
                      !isMobile
                        ? invest
                        : require("../assets/images/home/benefits-mobile.webp")
                    }
                    className={`fullHeight ${!isMobile ? "mt100 mb100" : ""}`}
                  /> */}
                </div>
              </Col>
            </Row>

            {/* services */}
            <Row id="services">
              <Col className="fullHeight fullWidth">
                {/* <Row className={"d-flex justify-content-center"}>
                  <p className={"sections-up-title"}>What do we offer?</p>
                </Row> */}
                <Row className={"d-flex justify-content-center"}>
                  <p
                    className={`sections-title ${!isMobile ? "mb100" : "mb30"}`}
                  >
                    Creation of Evidence-Based Protocols in 3 Steps
                  </p>
                </Row>
                <div className={`service-img-title ${!isMobile ? "mt80" : ""}`}>
                  <div className="evidence-based">
                    <div className="evidence-based-step">
                      <p className="number">
                        {" "}
                        <span>01</span>{" "}
                      </p>
                      <img
                        src={require(`../assets/images/explore-icon.svg`)}
                        width={60}
                        height={60}
                      />
                      <p className="evidence-based-title">Explore</p>
                      <p className="evidence-based-desc">
                        Analyze all available historical trial data
                        systematically
                      </p>
                    </div>
                    <div className="evidence-based-step">
                      <p className="number">
                        {" "}
                        <span>02</span>{" "}
                      </p>
                      <img
                        src={require(`../assets/images/select-icon.svg`)}
                        width={60}
                        height={60}
                      />
                      <p className="evidence-based-title">Select</p>
                      <p className="evidence-based-desc">
                        Define key variables and create benchmarks for your
                        protocol
                      </p>
                    </div>
                    <div className="evidence-based-step">
                      <p className="number">
                        {" "}
                        <span>03</span>{" "}
                      </p>
                      <img
                        src={require(`../assets/images/develop-icon.svg`)}
                        width={60}
                        height={60}
                      />
                      <p className="evidence-based-title">Develop</p>
                      <p className="evidence-based-desc">
                        Develop your protocol efficiently using the customized
                        generative Al
                      </p>
                    </div>
                  </div>

                  {/* <img
                    src={servicesHeader}
                    className={`${!isMobile ? "mb100" : "mb40"}`}
                  /> */}
                </div>
              </Col>
            </Row>

            {/* service items */}
            <Row>
              <Col className="fullHeight service-section mt80 sized-container">
                <Row>
                  <Col
                    md={{ span: 8, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    className={
                      "align-middle d-flex align-items-center justify-content-center"
                    }
                  >
                    <Col className={"service-item"} lg={{ span: 12 }}>
                      <p className={"title"}>1. Explore</p>
                      <p className={"content"}>
                        Protocol AI empowers experts to conduct a systematic
                        analysis of global data including historical trial
                        synopsis, protocols, linked publications and regulatory
                        decisions. The 360° view of trial data accelerates
                        decisions-making processes with historical evidence.
                        Harness the power of historical data to develop your
                        state-of-the-art trial protocol.
                      </p>
                      {/* <Col md={12} className="d-flex justify-content-end">
                        <a href="/service/meddev">Read More</a>
                      </Col> */}
                    </Col>
                  </Col>
                  <Col
                    md={{ span: 4, order: 2, offset: 0 }}
                    xs={{ span: 10, offset: 1, order: 1 }}
                    className={
                      "align-middle d-flex align-items-center justify-content-center"
                    }
                  >
                    <img
                      src={service1}
                      alt="Protocol optimization"
                      className="d-flex align-items-center"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col
                    md={{ span: 8, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    className={"align-middle d-flex align-items-start"}
                  >
                    <Col className={"service-item"} lg={{ span: 12 }}>
                      <p className={"title"}>2. Select</p>
                      <p className={"content"}>
                        Select the most appropriate parameters for your trial
                        based on evidence. Protocol AI is a co-pilot for all
                        experts involved in protocol development, enabling
                        internal and external teams to collaborate on one
                        platform and use evidence to back their strategic
                        decisions.
                      </p>
                      {/* <Col md={12} className="d-flex justify-content-end">
                        <a href="/service/risk-analyzer">Read More</a>
                      </Col> */}
                    </Col>
                  </Col>
                  <Col
                    md={{ span: 4, order: 2, offset: 0 }}
                    xs={{ span: 10, offset: 1, order: 1 }}
                    className={"align-middle d-flex align-items-start"}
                  >
                    <img
                      src={service2}
                      alt="Protocol optimization"
                      className="d-flex align-items-center"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col
                    md={{ span: 8, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    className={"align-middle d-flex align-items-start"}
                  >
                    <Col className={"service-item"} lg={{ span: 12 }}>
                      <p className={"title"}>3. Develop</p>
                      <p className={"content"}>
                        Efficiently develop your protocol with our fine-tuned
                        generative AI. Protocol AI seamlessly incorporates your
                        pre-selected parameters, enabling the swift and
                        automated writing of your tailored protocol.
                      </p>
                      {/* <Col md={12} className="d-flex justify-content-end">
                        <a href="/service/design-studio">Read More</a>
                      </Col> */}
                    </Col>
                  </Col>
                  <Col
                    md={{ span: 4, order: 2, offset: 0 }}
                    xs={{ span: 10, offset: 1, order: 1 }}
                    className={
                      "align-middle d-flex align-items-center justify-content-center"
                    }
                  >
                    <img
                      src={service3}
                      alt="Protocol optimization"
                      className="d-flex align-items-center"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* team section */}
            <Row id={"team"}>
              <div className={!isMobile && "col fullHeight"}>
                {/* <Row className={"d-flex justify-content-center"}>
                  <p className={"sections-up-title"}>Who are we?</p>
                </Row> */}
                {/* {!isMobile && (
                  <Row className={"d-flex justify-content-center"}>
                    <Link to="/team" className="whole-team">
                      <Nav.Link as="span" eventKey="Service">
                        <p className="whole-team whole-team-main">
                          Whole team to come <img src={chevron} alt="" />
                        </p>
                      </Nav.Link>
                    </Link>
                  </Row>
                )} */}
                <Row className={"d-flex justify-content-center mt50"}>
                  <p
                    className={`sections-title ${!isMobile ? "mb100" : "mb40"}`}
                  >
                    Our Team
                  </p>
                </Row>
                {/* {!!this.state.team?.length && (
                  <TeamMembersList members={this.state.team} />
                )} */}

                <div className="team-members-container">
                  {teamMembers.map((member) => (
                    <div key={member.id} className="team-member">
                      <div className="profile-pic">
                        <img
                          alt={`Profile of ${member.name}`}
                          src={member.profilePic}
                        />
                      </div>
                      <p className="persons-name">{member.name}</p>
                      <p className="position">{member.position}</p>
                    </div>
                  ))}
                </div>

                {/* <Slide autoplay={false}
                duration={1000}
                className="sized-container">
               {teamMemberSets.map((teamMemberSet, index) => (
          <div key={index} className="align-middle d-flex align-items-center justify-content-center each-slide">
            {teamMemberSet.map(member => (
              <div key={member.id} className="team-member">
                <div className="profile-pic">
                  <img alt={`profile-pic-${member.id}`} src={member.profilePic} />
                </div>
                <p className="persons-name">{member.name}</p>
                <p className="position">{member.position}</p>
              </div>
            ))}
          </div>
        ))}
              </Slide> */}
                {/*  {isMobile && (
                  <Row className={"d-flex justify-content-center"}>
                    <Link to="/team" className="whole-team">
                      <Nav.Link as="span" eventKey="Service">
                        <p className="whole-team whole-team-main">
                          Whole team to come <img src={chevron} alt="" />
                        </p>
                      </Nav.Link>
                    </Link>
                  </Row>
                )} */}
              </div>
            </Row>
          </Container>
        </div>
        <div className="advisory">
          <div className="page-container">
            <Container>
              {/* Advisory section */}
              <Row>
                <Col
                  className={`fullHeight advisory ${
                    !isMobile ? "mt100" : "mt40"
                  }`}
                >
                  {/* <Row className={"d-flex justify-content-center"}>
                    <p className={"sections-up-title"}>What says about us</p>
                  </Row> */}
                  <Row className={"d-flex justify-content-center"}>
                    <p className={"sections-title"}>Advisory Board</p>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
          <div
            className={`advisory-slide-container slider-grid ${
              !isMobile ? "mt80" : "mt30"
            }`}
          >
            <div className="page-container">
              <Slide
                autoplay={false}
                duration={1000}
                className="sized-container"
              >
                {/* new code for advisory board */}
                {advisoryBoardSets.map((advisoryDataSet, i) =>
                  advisoryDataSet.map((advisoryData, index) => (
                    <Row
                      className="each-slide d-flex align-self-center"
                      key={index}
                    >
                      <div className="advisory-image">
                        <img
                          src={advisoryData.imgSrc}
                          alt="risklick advisory items"
                        />
                      </div>
                      <Col className={"advisory-box"}>
                        <div>{advisoryData.name}</div>
                        {/* <p className={"title"}>{advisoryData.title}</p>
                    <p className={"subtitle"}>{advisoryData.position}</p> */}
                        <p className={"content"}>
                          {/* {ReactHtmlParser(advisoryData.description)} */}
                          {advisoryData.description}
                        </p>
                      </Col>
                    </Row>
                  ))
                )}
                {/* prev code for advisory board */}
                {/* {this.state.advisory &&
                  this.state.advisory.map((advisoryData, i) => (
                    <Row className="each-slide d-flex align-self-center">
                      <div className="advisory-image">
                        <img src={advisoryData.picture} alt="risklick advisory items"/>
                      </div>
                      <Col className={"advisory-box"}>
                        <div>{advisoryData.name}</div>
                        <p className={"title"}>{advisoryData.title}</p>
                        <p className={"subtitle"}>{advisoryData.position}</p>
                        <p className={"content"}>
                          {ReactHtmlParser(
                            ReactHtmlParser(advisoryData.description)
                          )}
                        </p>
                      </Col>
                    </Row>
                  ))} */}
              </Slide>
            </div>
          </div>
        </div>
        <div className="page-container">
          <Container>
            {/* news section */}
            <Row id="news" className={`${!isMobile ? "mt100" : "mt40"} `}>
              <div className="fullHeight">
                <Row className={"d-flex justify-content-center"}>
                  <p className={"sections-up-title"}>What is new?</p>
                </Row>
                <Row className={"d-flex justify-content-center"}>
                  <Link to="/news" className="whole-team">
                    <Nav.Link as="span" eventKey="Service">
                      <p className="whole-team whole-team-main">
                        More news <img src={chevron} alt="" />
                      </p>
                    </Nav.Link>
                  </Link>
                </Row>
                <Row className={"d-flex justify-content-center"}>
                  <p className={"sections-title"}>News</p>
                </Row>
                <Row className={!isMobile ? "mt100" : "mt30"}>
                  {this.state.newList &&
                    this.state.newList.map((news, i) => (
                      <div className={!isMobile && "col-lg-6"} key={i}>
                        <Col className="home-news-card" md={{ span: 12 }}>
                          <img
                            src={news.picture || protocolaiLogo}
                            alt="news"
                          />
                          <p className="title">{news.title}</p>
                          <p className="description">{news.short_body}</p>
                          <p className="read-more">
                            <a href={`/news/${news.slug}`}>Read More</a>
                          </p>
                        </Col>
                      </div>
                    ))}
                </Row>
              </div>
            </Row>

            {/*partners section*/}
            <Row>
              <Col className={`fullHeight ${!isMobile ? "mt50" : "mt40"}`}>
                {/* <Row className={"d-flex justify-content-center"}>
                  <p className={"sections-up-title"}>Who works with us...</p>
                </Row> */}
                <Row className={"d-flex justify-content-center"}>
                  <p className={"sections-title"}>Partners</p>
                </Row>
                <Row className={`fullHeight ${!isMobile ? "mt100" : "mt30"}`}>
                  {partners.map((partner, i) => (
                    <Col
                      sm={{ span: 12 }}
                      md={{ span: 6 }}
                      lg={{ span: 3 }}
                      className={"partners sized-container mb100"}
                    >
                      <img src={partner.srcImg} alt="partners" key={i} />
                    </Col>
                  ))}
                  {/* {this.state.partners &&
                    this.state.partners.map((partner, i) => (
                      <Col
                        sm={{ span: 12 }}
                        md={{ span: 6 }}
                        lg={{ span: 3 }}
                        className={"partners sized-container mb50"}
                      >
                        <img
                          src={partner.picture}
                          alt="partners"
                          key={i}
                        />
                      </Col>
                    ))} */}
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    );
  }
}

export default IndexPage;
