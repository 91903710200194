const runCrisp = () => {
  // Include the Crisp code here, without the <script></script> tags
  window.$crisp = [];
  window.CRISP_WEBSITE_ID = "0608539d-d012-4938-9f44-f520d9862c16";

  (function() {
    var d = document;
    var s = d.createElement("script");

    s.src = "https://client.crisp.chat/l.js";
    s.async = 1;
    d.getElementsByTagName("head")[0].appendChild(s);
  })();

  window.$crisp.push(["do", "chat:show"]);
};

export default runCrisp
